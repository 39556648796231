import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[numbersOnly]",
  standalone: true,
})
export class NumbersOnlyDirective {
  constructor(private ele: ElementRef) {}

  private check(value: string) {
    return String(value).match(new RegExp(/^\d+$/));
  }

  private run(oldValue: any) {
    setTimeout(() => {
      const currentValue: string = this.ele.nativeElement.value;
      if (currentValue !== "" && !this.check(currentValue)) {
        this.ele.nativeElement.value = oldValue;
      }
    });
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    this.run(this.ele.nativeElement.value);
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    this.run(this.ele.nativeElement.value);
  }
}
