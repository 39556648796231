<ion-header class="px-2 border-b ion-no-border">
  <ion-toolbar>
    <ion-title> 제휴사 문의하기 </ion-title>
    <ion-button slot="end" fill="clear" (click)="dismiss()" color="danger">
      취소
    </ion-button>
  </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="true" class="ion-padding">
  <form
    class="flex flex-col h-full gap-2"
    [formGroup]="form"
    (ngSubmit)="submit()"
  >
    <ion-input
      class="border-b"
      label="제목"
      placeholder="제목을 입력해주세요"
      formControlName="title"
    ></ion-input>
    <ion-input
      class="border-b"
      label="이메일"
      type="email"
      placeholder="이메일을 입력해주세요 (coinrefund@example.com)"
      formControlName="email"
    ></ion-input>
    <ion-input
      class="border-b"
      numbersOnly
      type="tel"
      maxlength="11"
      label="전화번호"
      placeholder="전화번호를 입력해주세요"
      formControlName="tel"
    ></ion-input>
    <ion-textarea
      class="border-b"
      label="내용"
      placeholder="내용을 입력해주세요"
      formControlName="content"
    ></ion-textarea>
    <ion-button
      class="mt-auto"
      type="submit"
      [disabled]="form.invalid"
      expand="block"
    >
      제출하기
    </ion-button>
  </form>
</ion-content>
