import {
  CreateInquiryDto,
  InquiryDto,
  InquiryService,
  UpdateInquiryDto,
} from "@/api";
import { Injectable, OnDestroy, inject } from "@angular/core";
import { InquiryControllerSearch$Params } from "api/src/lib/fn/inquiry/inquiry-controller-search";
import { Observable, Subject, shareReplay, takeUntil } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class InquiryApiService implements OnDestroy {
  private readonly inquiryService = inject(InquiryService);
  private readonly clearCache$ = new Subject<void>();
  private cache$?: Observable<InquiryDto[]>;

  ngOnDestroy() {
    this.clearCache$.next();
    this.clearCache$.complete();
  }

  search(options: InquiryControllerSearch$Params) {
    return this.inquiryService.inquiryControllerSearch(options);
  }

  findAll() {
    if (!this.cache$) {
      this.cache$ = this.inquiryService
        .inquiryControllerFindAll()
        .pipe(shareReplay(1), takeUntil(this.clearCache$));
    }
    return this.cache$;
  }

  findById(id: string) {
    return this.inquiryService.inquiryControllerFindById({ id });
  }

  create(body: CreateInquiryDto) {
    return this.inquiryService.inquiryControllerCreate({ body });
  }

  update(id: string, body: UpdateInquiryDto) {
    return this.inquiryService.inquiryControllerUpdate({ id, body });
  }

  delete(id: string) {
    return this.inquiryService.inquiryControllerDelete({ id });
  }

  clearCache = () => {
    this.clearCache$.next();
    this.cache$ = undefined;
  };

  refresh = () => {
    this.clearCache();
    return this.findAll();
  };
}
