import { Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import DefaultComponent from "./layouts/default/default.component";

export const appRoutes: Routes = [
  {
    path: "",
    component: DefaultComponent,
    children: [
      {
        path: "",
        children: [
          {
            path: "",
            loadComponent: () =>
              import("./pages/home/home.component").then(
                (p) => p.HomeComponent,
              ),
          },
        ],
      },
      {
        path: "affiliate",
        children: [
          {
            path: "",
            loadComponent: () =>
              import("./pages/affiliate/affiliate.component").then(
                (p) => p.AffiliateComponent,
              ),
          },
        ],
      },
      {
        path: "company",
        children: [
          {
            path: "",
            loadComponent: () =>
              import("./pages/company/company.component").then(
                (p) => p.CompanyComponent,
              ),
          },
        ],
      },
      {
        path: "event",
        children: [
          {
            path: "",
            loadComponent: () =>
              import("./pages/event/event.component").then(
                (p) => p.EventComponent,
              ),
          },
        ],
      },
      {
        path: "login",
        children: [
          {
            path: "",
            loadComponent: () =>
              import("./pages/login/login.component").then(
                (p) => p.LoginComponent,
              ),
          },
        ],
      },
      {
        path: "signup",
        children: [
          {
            path: "",
            loadComponent: () =>
              import("./pages/signup/signup.component").then(
                (p) => p.SignupComponent,
              ),
          },
        ],
      },
      {
        path: "my-page",
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            loadComponent: () => import("./pages/my-page/my-page.component"),
          },
        ],
      },
      {
        path: "service-of-terms",
        children: [
          {
            path: "",
            loadComponent: () =>
              import("./pages/service-of-terms/service-of-terms.component"),
          },
        ],
      },
      {
        path: "privacy-policy",
        children: [
          {
            path: "",
            loadComponent: () =>
              import("./pages/privacy-policy/privacy-policy.component"),
          },
        ],
      },
      {
        path: "verify-email",
        children: [
          {
            path: "",
            loadComponent: () =>
              import("./pages/verify-email/verify-email.component"),
          },
        ],
      },
    ],
  },
  {
    path: "",
    redirectTo: "",
    pathMatch: "full",
  },
];
