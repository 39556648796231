import { Directive, OnInit } from "@angular/core";
import sal from "sal.js";

@Directive({
  selector: "[sal]",
  standalone: true,
})
export class SalDirective implements OnInit {
  salAPI?: sal.API;
  ngOnInit(): void {
    this.salAPI = sal();
  }
}
