import { NumbersOnlyDirective } from "@/common";
import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import {
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { IonicModule, ModalController } from "@ionic/angular";
import { InquiryApiService } from "../../services/inquiry-api.service";
import { ToastService } from "../../services/toast.service";

@Component({
  selector: "app-create-inquiry-modal",
  templateUrl: "./create-inquiry-modal.component.html",
  styleUrls: ["./create-inquiry-modal.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    NumbersOnlyDirective,
  ],
})
export default class CreateInquiryModalComponent {
  fb = inject(NonNullableFormBuilder);
  form = this.fb.group({
    title: ["", [Validators.required]],
    content: ["", [Validators.required]],
    tel: ["", [Validators.required, Validators.pattern(/^\d{3}\d{3,4}\d{4}$/)]],
    email: ["", [Validators.required, Validators.email]],
  });
  inquiryApi = inject(InquiryApiService);
  toastService = inject(ToastService);
  modalController = inject(ModalController);

  dismiss() {
    this.modalController.dismiss();
  }

  submit() {
    const body = this.form.getRawValue();
    this.inquiryApi.create(body).subscribe(() => {
      this.toastService.show(
        "문의가 성공적으로 등록되었습니다. 확인 후 연락드리겠습니다.",
      );
      this.modalController.dismiss();
    });
  }
}
