import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[noWhitespace]",
  standalone: true,
})
export class NoWhitespaceDirective {
  constructor(private ele: ElementRef) {}

  private run() {
    setTimeout(() => {
      const currentValue: string = this.ele.nativeElement.value;
      const noWhitespace = currentValue.replaceAll(/\s/g, "");
      this.ele.nativeElement.value = noWhitespace;
    });
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    this.run();
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    this.run();
  }
}
